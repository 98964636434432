<script>
import slideshowService from '@/services/slideshow.service'

export default {
  name: 'Slideshow',
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this._get()
  },
  methods: {
    _get() {
      slideshowService.getAll()
        .then(res => {
          this.items = res.data
        })
    },
    updatePosition(items) {
      this.items = items
      this.items.map((item, i) => {
        item.position = i + 1
        let modelItem = { ...item }
        delete modelItem.photo
        this.edit({ data: modelItem })
        return item
      })
    },
    edit({ data }) {
      slideshowService.edit({ data })
        .then(_res => {
          this.$notify({
            message: this.$tc('m.itemSuccessfullySaved', { item: this.$tc('slide') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
    remove(id) {
      slideshowService.remove({ id })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('slide') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
          this.updatePosition(this.items)
        })
    }
  }
}
</script>

<template>
  <BaseCard>
    <div
      slot="header"
      class="text-right"
    >
      <router-link :to="{ name: 'singleSlideshow', params: { id: 'new' } }">
        <BaseButton
          type="green"
          gradient
        >
          {{ $t('addNewItem', { item: $tc('slide') }) }}
        </BaseButton>
      </router-link>
    </div>
    <div>
      <BaseDraggableList
        :items="items"
        model="slideshow"
        @updatePosition="updatePosition"
        @remove="remove"
      />
    </div>
  </BaseCard>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.list
  padding: 0
  list-style: none
.head
  padding: 0 $base-spacing
.item
  padding: $base-spacing
  background: $blue-gradient
  border-radius: 8px
  &Inner
    display: grid
    grid-template-columns: 60px 120px auto 60px 60px 60px
    grid-gap: $base-spacing
.item~.item
  margin-top: $base-spacing
.photo
  position: relative
</style>
